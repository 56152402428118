import DividerLine from 'components/DividerLine'
import Image from 'components/Image'
import cx from 'classnames'

export default function IconGrid({ icons, isMediaHiddenOnMobile }) {
  if (icons) {
    return (
      <>
        <DividerLine
          className={cx('mb-30', {
            'hidden m:block': !isMediaHiddenOnMobile,
          })}
        />
        <div className="overflow-x-auto m:overflow-hidden hide-scrollbar -mx-15 m:mx-0 mt-20">
          <ul className="-mx-10 2xs:-mx-15 m:mx-0 gap-y-30 m:gap-y-0 grid grid-cols-2 l:grid-cols-4 m:-mt-20 xl:-mt-30">
            {icons?.map((icon) => {
              return (
                <li className="text-center px-10 2xs:px-15 m:px-0 mt-20 l:mt-40" key={icon._key}>
                  <div className="w-115 2xs:w-120 xs:w-135 m:w-115 mx-auto m:mx-0 space-y-16">
                    <div className={cx('mx-auto', {
                      'w-60': !icon.iconOriginalSize
                    })} style={icon.iconOriginalSize ? { width: `${icon?.image?.asset?.metadata?.dimensions?.width}px`, height: `${icon?.image?.asset?.metadata?.dimensions?.height}px` } : {}}>
                      <Image
                        image={icon.image}
                        alt="Icon Image"
                        width={icon.iconOriginalSize ? icon?.image?.asset?.metadata?.dimensions?.width : 60}
                        height={icon.iconOriginalSize ? icon?.image?.asset?.metadata?.dimensions?.height : 60}
                        sizes="60px"
                        placeholder={false}
                        fit="cover"
                      />
                    </div>
                    <p className="text-14 leading-20 m:text-16 m:leading-22 font-medium">{icon.caption}</p>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </>
    )
  } else {
    return null
  }
}