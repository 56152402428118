import TextButton from 'components/TextButton'
import Button from 'components/Button'
import cx from 'classnames'

const CustomButton = ({ button, link, isLinkButton, isScreenMedium, module, analytics, addArrowIcon = false }) => {
  const buttonClasses = button?.buttonType !== "textBtn" ? "mt-20 l:mt-40" : "mt-15 m:mt-20"

  return isLinkButton ? (
    link ? (
      <TextButton
        className="mt-15 m:mt-20"
        link={link}
        size={isScreenMedium ? 'large' : 'compact'}
        chevron={true}
        onClick={() => analytics.trackButtonClick(link.title)}
      >
        <span aria-hidden="true">{link.title}</span>
        <span className="sr-only">
          {module?.overline ? `${link.title} about ${module.overline}` : link.title}
        </span>
      </TextButton>
    ) : null
  ) : (
    <Button
      variant={button.buttonType}
      size="large"
      link={button.link}
      className={buttonClasses}
      srOnlyText={module?.overline ? `${button.link.title} about ${module.overline}` : button.link.title}
      onClick={() => analytics.trackButtonClick(button.link.title)}
    >
      <div className={cx({
        'flex items-center text-18': addArrowIcon,
      })}>
        {button?.link?.title}
        {addArrowIcon && (
          <svg width="6" height="8" className='ml-10' viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g mask="url(#mask0_10162_2483)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.155 6.46695C0.872773 6.74918 0.872773 7.20676 1.155 7.48898C1.43723 7.77121 1.8948 7.77121 2.17703 7.48898L5.66602 4L2.17703 0.511016C1.8948 0.22879 1.43723 0.228789 1.155 0.511016C0.872772 0.793243 0.872773 1.25082 1.155 1.53305L3.62195 4L1.155 6.46695Z" fill="currentColor" />
            </g>
          </svg>
        )}
      </div>
    </Button>
  )
}

export default CustomButton