import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import cx from 'classnames'
import { useRef } from 'react'
import { useRect } from '@reach/rect'
import BlockContent from '@sanity/block-content-to-react'
import serializers from 'serializers/justLinks'
import Image from 'components/Image'

export default function AccordionItems({ item, description, index }) {
  return (
    <Accordion collapsible={true} multiple={true} defaultIndex={[]}>
      <AccordionItem
        className={cx('border-b border-navy-blue-muted border-opacity-25', {
          'border-t': index === 0,
        })}
      >
        <AnimatedAccordionButton>{item.heading}</AnimatedAccordionButton>
        <AnimatedAccordionPanel>
          {item.image ? (
            <div className="w-72 h-72 l:w-85 l:h-85 rounded-50 overflow-hidden mr-16 l:mr-24 mt-2 flex-shrink-0">
              <Image
                image={item.image}
                alt=""
                width={85}
                height={85}
                placeholder={false}
              />
            </div>
          ) : null}
          {description ? (
            typeof description === 'string' ? (
              <p className="text-13 leading-18">{description}</p>
            ) : (
              <div className="text-13 leading-18">
                <BlockContent blocks={description} serializers={serializers} />
              </div>
            )
          ) : null}
        </AnimatedAccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

const AnimatedAccordionButton = ({ children }) => {
  const { isExpanded } = useAccordionItemContext()
  return (
    <AccordionButton
      className="text-13 m:text-16 font-medium leading-100 py-20 m:py-24 w-full items-center text-left flex justify-between pr-10 rounded transition focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-royal-blue"
    >
      <div>{children}</div>
      <div className="w-8 h-8 relative flex justify-center">
        <div className={cx('transition ease-in-out-cubic', {
          'transform -rotate-180': isExpanded
        })}>
          <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.03394 0.625781C1.65976 0.251597 1.05309 0.251601 0.678899 0.625788C0.304724 0.999963 0.304722 1.60664 0.678904 1.98082L5.30469 6.6066L9.93047 1.98082C10.3047 1.60664 10.3047 0.999963 9.93048 0.625788C9.55629 0.251601 8.94962 0.251598 8.57543 0.625781L5.30469 3.89653L2.03394 0.625781Z" fill="#003057" />
          </svg>
        </div>
      </div>
    </AccordionButton>
  )
}

const AnimatedAccordionPanel = ({ children }) => {
  const { isExpanded } = useAccordionItemContext()
  const ref = useRef()
  const rect = useRect(ref)

  return (
    <AccordionPanel
      className="text-13 overflow-y-hidden transition-all ease-in-out-cubic"
      style={{ maxHeight: isExpanded ? rect?.height : 0 }}
      hidden={false}
      aria-hidden={!isExpanded || undefined}
    >
      <div ref={ref} className="pb-22 flex items-center" inert={isExpanded ? null : ''}>
        {children}
      </div>
    </AccordionPanel>
  )
}