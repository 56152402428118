import Image from 'components/Image'
import BlockContent from '@sanity/block-content-to-react'
import serializers from 'serializers/justLinks'
import AccordionItems from './Accordion';

const DescriptionItems = ({ descriptionListItems }) => {
  return (
    <ul className="py-14 m:py-16 l:py-24 -mb-16">
      {descriptionListItems?.map((item, index) => {
        const description = item.descriptionWithFormatting || item.description;
        return item.accordionItem ? (
          <li key={index}>
            <AccordionItems item={item} description={description} index={index} />
          </li>
        ) : (
          <li key={index} className="flex items-center mb-16">
            {item.image ? (
              <div className="w-72 h-72 l:w-85 l:h-85 rounded-50 overflow-hidden mr-16 l:mr-24 mt-2">
                <Image
                  image={item.image}
                  alt=""
                  width={85}
                  height={85}
                  placeholder={false}
                />
              </div>
            ) : null}
            <div className="flex-1">
              <h3 className="text-15 leading-21.3 m:text-16 m:leading-16 l:text-20 l:leading-26 font-medium mb-4">{item.heading}</h3>
              {description ? (
                typeof description === 'string' ? (
                  <p className="text-13 leading-18 l:text-16 l:leading-23">{description}</p>
                ) : (
                  <div className="text-13 leading-18 l:text-16 l:leading-23">
                    <BlockContent blocks={description} serializers={serializers} />
                  </div>
                )
              ) : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default DescriptionItems;